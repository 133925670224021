export const environment = {
    apiPrefix: '/api',
    appName: "VolkerWessels Beeldbank",
    appNameShort: "Beeldbank",
    azureClientId: `7d2e65ed-c82b-46f8-b1ae-ecb67c9c4f8e`,
    azureLogoutUri: `https://beeldbank.acc.recognize.hosting`,
    azureReadApi: `api://7d2e65ed-c82b-46f8-b1ae-ecb67c9c4f8e/Read`,
    azureRedirectUri: `https://beeldbank.acc.recognize.hosting`,
    loginDescription: "Klik hieronder op inloggen om in te loggen met uw VolkerWessels account.",
    maxDocumentSizeMB: 20,
    missingRoles: "U heeft nog geen toegang tot de Beeldbank. Neem contact op met uw beheerder.",
    production: true,
    showLoginShape: false,
    useMock: false,
    applicationScope: null,
    featureToggles: [],
    vistaCreateApiKey: null,
    roleLabels: {
        'ROLE_COMMUNICATION_OFFICER': 'Communication officer',
        'ROLE_ORGANIZATION_ADMIN': 'Organisatie-admin',
        'ROLE_USER_MANAGER': 'Gebruikersbeheerder',
        'ROLE_MARKETING': 'Marketing',
        'ROLE_SUPER_ADMIN': 'IT-admin',
    }
};
